<div class="d-flex justify-content-center mt-4">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">{{text['P10_Headline']}}</p>
      <p class="font-3">{{text['P10_Info']}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 wizard-col">
          <div class="form-group form-group-IV" [ngStyle]="{'background-color': switch_button_state ? style['p'] : style['s']}" (click)="toggleState()">
            <div class="IV-checkbox form-control form-item shadow-IV clear-background">
              <div *ngIf="switch_button_state">
                <i class="fas fa-arrow-up switch-button-icon"></i>
                <p class="switch-button-text">{{text['P10_Button_Toggle_2']}}</p>
              </div>

              <div *ngIf="!switch_button_state">
                <i class="fas fa-arrow-down switch-button-icon"></i>
                <p class="switch-button-text">{{text['P10_Button_Toggle_1']}}</p>
              </div>
            </div>
          </div>

          <div *ngIf="switch_button_state">
            <form [formGroup]="HrForm">
              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="HrForm.controls['hrNummer'].errors && submitted"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Handelsregisternummer*"
                  formControlName="hrNummer"
                  required
                  (input)="changeMe('hHrNummer', $event)"/>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="HrForm.controls['amtgericht'].errors && submitted"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Amtsgericht*"
                  formControlName="amtgericht"
                  required
                  (input)="changeMe('hAmtgericht', $event)"/>
              </div>
            </form>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button *ngIf="style" [ngStyle]="{'background-color': style['t']}" (click)="onBack()" class="btn btn-prev-step btn-full-size btn-big text-bold font-5 shadow-IV">{{ text['P10_Button_Abort'] }}</button>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button *ngIf="style" [ngStyle]="{'background-color': style['p']}" class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV" (click)="onSubmit()">{{ text['P10_Button_Next'] }}</button>
        </div>
      </div>
    </div>

    <div class="progress">
      <div class="progress-bar" role="progressbar" style="width: 76%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="style" [ngStyle]="{'background-color': style['p']}"></div>
    </div>
  </div>
</div>
