import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {TextService} from './services/text.service';
import {IdentityService} from './services/identity.service';
import {StyleService} from './services/style.service';
import {WebsiteRefersService} from './services/website-refers.service';
import {WebsiteRefersModel} from './models/website-refers.model';
import {environment} from '../environments/environment';
import {StyleModel} from './models/style.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public myRouter: Router;
  public currentUrl = null;
  public text;
  public style: StyleModel;
  public adminUrl: string = environment.adminUrl;

  constructor(
    private router: Router,
    private location: Location,
    private _http: HttpClient,
    private _identityService: IdentityService,
    private _textService: TextService,
    private _styleService: StyleService,
    private _websiteRefersService: WebsiteRefersService
  ) {
    this.text = this._textService.text.subscribe(text => {
      this.text = text;
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });

    this.currentUrl = window.location.hostname;

    const formData = new FormData();

    formData.append('Subdomain', environment.subdomain);

    this._http.post(environment.apiUrl + '/Tenants/GetIdentity', formData).subscribe(data => {
      this._identityService.updateSubdomain(data[0]);
      this._identityService.updateTenantData({
        guestPhotoUpload: data[6] === 'True',
        gridInstallerPhotoUpload: data[7] === 'True'
      });

      let commodityPower = data[2] === 'True';
      let commodityGas = data[3] === 'True';
      let commodityWater = data[4] === 'True';
      let commodityGasWater = data[5] === 'True';
      this._identityService.updateCommodities([commodityPower, commodityGas, commodityWater, commodityGasWater]);

      this._http.get(`${environment.apiUrl}/Texts/GetApplicationTexts`)
        .subscribe(data => {
          this._textService.updateText(data);
        });

      this._http.get<WebsiteRefersModel>(`${environment.apiUrl}/Clients/GetWebsiteRefers`)
        .subscribe((websiteRefers: WebsiteRefersModel) => {
          this._websiteRefersService.updateWebsiteRefers(websiteRefers);
        });

      this._http.get<StyleModel>(environment.apiUrl + '/Clients/GetColor').subscribe((client_settings: StyleModel) => {
        this._styleService.updateStyle(client_settings);
        this.style = client_settings;
        const backgroundUrl = environment.apiUrl + '/Clients/GetBackgroundImage/' + client_settings['bgi'];
        document.body.style.backgroundImage = `url('${backgroundUrl}')`;

        const logoElement = document.getElementById('logo_image');
        const favicon = document.getElementById('favicon');
        if (client_settings['logo']) {
          logoElement.setAttribute('src', `${environment.apiUrl}/Clients/GetBackgroundImage/${client_settings['logo']}`);
          favicon.setAttribute('href', `${environment.apiUrl}/Clients/GetBackgroundImage/${client_settings['logo']}`);
        }
      });
    }, (error: HttpErrorResponse) => {
      if (error.status === 404) {
        this._identityService.updateTenantData(null);
        this.router.navigate(['not-found']).then();
      }
    });
  }

  public ngOnInit(): void {
    const currentPath = this.location.path(false);
    const allowDeepLinkNavigation = currentPath === '/' || currentPath === '/liste';
    if (!allowDeepLinkNavigation) {
      this.router.navigate(['']).then();
    }
  }

  public listeAnzeigen(): void {
    this.router.navigate(['/liste']).then();
  }
}
