import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {TextService} from 'src/app/services/text.service';
import {StyleService} from 'src/app/services/style.service';
import {StyleModel} from "../../models/style.model";

@Component({
  selector: 'app-unfallkasse',
  templateUrl: './unfallkasse.component.html',
  styleUrls: ['./unfallkasse.component.scss']
})

export class UnfallkasseComponent implements OnInit {
  myRouter: Router;
  message: string = "Bitte geben Sie die Informationen zu Ihrer Unfallkasse ein!";
  aData = null;
  value = null;
  text;
  public style: StyleModel;

  constructor(private router: Router, private data: DataService, TextService: TextService, private _styleService: StyleService) {
    this.aData = data.data;
    this.value = this.aData['unfallkasse'];
    this.myRouter = this.router;

    TextService.text.subscribe(text => {
      this.text = text;
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });
  }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  onSubmit() {
    this.router.navigate(['/haftpflichtversicherung']);
  }

  changeMe(control: string, event) {
    this.data.updateData(control, event.target.value);
    this.value = event.target.value;
  }

  onBack() {
    this.router.navigate(['/handelsregister']);
  }
}
