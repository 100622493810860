import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoModalComponent} from './info-modal.component';

@NgModule({
  declarations: [
    InfoModalComponent
  ],
  exports: [
    InfoModalComponent
  ],
  imports: [
    CommonModule
  ]
})

export class InfoModalModule {}
