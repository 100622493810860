import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms'
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {OnlyNumbers} from '../../validators/only_numbers';
import {OnlyCharacters} from '../../validators/only_characters';
import {ValidZip} from '../../validators/zip';
import {TextService} from '../../services/text.service';
import {StyleService} from '../../services/style.service';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {StyleModel} from '../../models/style.model';
import {ApplicationModel} from '../../models/application.model';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})

export class CompanyComponent implements OnInit {
  public message: string = 'Angaben zu Ihrem Firmensitz';
  public form: UntypedFormGroup;
  public submitted: boolean = false;
  public selectedCountry: string;
  public countries: string[] = [];
  public text;
  public style: StyleModel;
  public application: ApplicationModel = new ApplicationModel();

  constructor(
    private fb: UntypedFormBuilder,
    private _router: Router,
    private _dataService: DataService,
    private _textService: TextService,
    private _styleService: StyleService,
    private _http: HttpClient
  ) {}

  public ngOnInit(): void {
    this.application = this._dataService.data;

    this.form = this.fb.group({
      companyName: [this.application['cName'], [Validators.required]],
      street: [this.application['cStreet'], [Validators.required, OnlyCharacters]],
      houseNumber: [this.application['cHouseNumber']],
      zip: [this.application['cZip'], [Validators.required, ValidZip]],
      country: [this.application['cCountry']],
      city: [this.application['cCity'], [Validators.required, OnlyCharacters]],
      mail: [this.application['cMail'], [Validators.required, Validators.email]],
      phoneNumber: [this.application['cPhoneNumber'], [Validators.required, OnlyNumbers]],
    });

    this.selectedCountry = this.application['cCountry'];

    this._textService.text.subscribe(text => {
      this.text = text;
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });

    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this._http.get(environment.adminApiUrl + 'api/Clients/CountriesConfiguration').subscribe((data: any) => {
      this.countries = data.allAvailableCountries;
      this.selectedCountry = this.selectedCountry ? this.selectedCountry : data.defaultCountry;
    }, () => {
      this.selectedCountry = 'Deutschland';
      this.countries = [this.selectedCountry]
    });
  }

  public changeMe(control: string, event) {
    this._dataService.updateData(control, event.target.value);
  }

  public onSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      this._router.navigate(['/workshop']).then();
    }
  }

  public onBack(): void {
    this._router.navigate(['/']).then();
  }
}
