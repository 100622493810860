<div class="message-dialog">
  <h1 matDialogTitle>{{title}}</h1>
  <div mat-dialog-content>{{message}}</div>
  <div mat-dialog-actions class="pt-24 actions">
    <button
      mat-raised-button
      class="btn btn-submit btn-big text-bold font-5 shadow-IV"
      [ngStyle]="{'background-color': style['p']}"
      cdkFocusInitial
      (click)="dialogRef.close(true)">
      {{confirmButton || 'Ok'}}
    </button>
  </div>
</div>
