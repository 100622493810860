<div class="d-flex justify-content-center mt-4">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">{{text['P3_Headline']}}</p>
      <p class="font-3">{{text['P3_Info']}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 wizard-col">
          <form [formGroup]="form">
            <div class="form-group form-group-IV">
              <select
                [class.form-item-invalid]="form.controls['salutation'].errors && submitted"
                class="form-control form-control form-item shadow-IV"
                formControlName="salutation"
                required
                (change)="changeMe('oSalutation', $event.target['value'])">
                <option value="0">Anrede*</option>
                <option *ngFor="let salutation of salutations" [value]="salutation.value">
                  {{salutation.label}}
                </option>
              </select>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['title'].errors && submitted"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Titel"
                formControlName="title"
                (input)="changeMe('oTitle', $event.target['value'])"/>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['firstName'].errors && submitted || form.controls['firstName'].errors?.['OnlyCharacters']"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Vorname*"
                formControlName="firstName"
                required
                (input)="changeMe('oFirstName', $event.target['value'])"/>
              <p class="error-message pull-left" *ngIf="form.controls['firstName'].errors?.['OnlyCharacters']">
                Darf keine Ziffern oder Sonderzeichen enthalten.
              </p>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['lastName'].errors && submitted || form.controls['lastName'].errors?.['OnlyCharacters']"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Nachname*"
                formControlName="lastName"
                required
                (input)="changeMe('oLastName', $event.target['value'])"/>
              <p class="error-message pull-left" *ngIf="form.controls['lastName'].errors?.['OnlyCharacters']">
                Darf keine Ziffern oder Sonderzeichen enthalten.
              </p>
            </div>

            <div class="form-group-IV checkbox-field">
              <mat-checkbox color="primary" formControlName="overtakeCompanyValues" (change)="onOvertakeCompanyValuesChange($event.checked)">
                Kontaktdaten von den Unternehmensangaben übernehmen
              </mat-checkbox>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['phoneNumber'].errors && submitted || form.controls['phoneNumber'].errors?.['OnlyNumbers']"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Telefon*"
                formControlName="phoneNumber"
                required
                (input)="changeMe('oPhoneNumber', $event.target['value'])"/>
              <p class="error-message pull-left" *ngIf="form.controls['phoneNumber'].errors?.['OnlyNumbers']">
                Darf nur Ziffern enthalten.
              </p>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['mobileNumber'].errors && submitted || form.controls['mobileNumber'].errors?.['OnlyNumbers']"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Mobil"
                formControlName="mobileNumber"
                (input)="changeMe('oMobileNumber', $event.target['value'])"/>
              <p class="error-message pull-left" *ngIf="form.controls['mobileNumber'].errors?.['OnlyNumbers']">
                Darf nur Ziffern enthalten.
              </p>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['mail'].errors && submitted || form.controls['mail'].errors?.['email']"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="E-Mail*"
                formControlName="mail"
                required (input)="changeMe('oMail', $event.target['value'])"/>
              <p class="error-message pull-left" *ngIf="form.controls['mail'].errors?.['email']">
                Gültige E-Mail Adresse eingeben.
              </p>
            </div>
          </form>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button
            *ngIf="style"
            [ngStyle]="{'background-color': style['t']}"
            (click)="onBack()"
            class="btn btn-prev-step btn-full-size btn-big text-bold font-5 shadow-IV">
            {{text['P3_Button_Abort']}}
          </button>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button
            *ngIf="style"
            [ngStyle]="{'background-color': style['p']}"
            class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV"
            (click)="onSubmit()">
            {{text['P3_Button_Next']}}
          </button>
        </div>
      </div>
    </div>

    <div class="progress" *ngIf="!application.isGuest">
      <div
        *ngIf="style"
        class="progress-bar"
        role="progressbar"
        style="width: 16%"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{'background-color': style['p']}">
      </div>
    </div>

    <div class="progress" *ngIf="application.isGuest">
      <div
        *ngIf="style"
        class="progress-bar"
        role="progressbar"
        style="width: 40%"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{'background-color': style['p']}">
      </div>
    </div>
  </div>
</div>
