import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {StyleModel} from "../models/style.model";

@Injectable({providedIn: 'root'})

export class StyleService {
  private style: StyleModel = new StyleModel();
  private styleArray = new BehaviorSubject(this.style);
  public styleObservable = this.styleArray.asObservable();

  public updateStyle(style: StyleModel): void {
    this.style = style;
    this.styleArray.next(this.style);
  }
}
