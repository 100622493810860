import {SelectOptionInterface} from '../interfaces/select-option.interface';
import {EnumLabelResolver} from './enum-label-resolver';
import {SalutationEnum} from '../enums/salutation.enum';

export const Salutations: SelectOptionInterface[] = [
  {
    label: EnumLabelResolver.salutation[SalutationEnum.Woman],
    value: SalutationEnum.Woman
  },
  {
    label: EnumLabelResolver.salutation[SalutationEnum.Man],
    value: SalutationEnum.Man
  },
  {
    label: EnumLabelResolver.salutation[SalutationEnum.Divers],
    value: SalutationEnum.Divers
  }
];
