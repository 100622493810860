export class ApplicationModel {
  public isGuest: boolean = null;
  public dataProtectionConfirmed: boolean = false;
  public commodities: any[] = [];
  public cName: string = '';
  public cStreet: string = '';
  public cHouseNumber: string = '';
  public cCountry: string = 'Deutschland';
  public cZip: string = '';
  public cCity: string = '';
  public cPhoneNumber: string = '';
  public cMail: string = '';
  public wToggle: boolean = false;
  public wName: string = '';
  public wStreet: string = '';
  public wHouseNumber: string = '';
  public wZip: string = '';
  public wCity: string = '';
  public wFitted: boolean = false;
  public oSalutation: number = 0;
  public oTitle: string = '';
  public oFirstName: string = '';
  public oLastName: string = '';
  public oPhoneNumber: string = '';
  public oMobileNumber: string = '';
  public oMail: string = '';
  public eToggleState: boolean = true;
  public eSalutation: number = 0;
  public eTitle: string = '';
  public eFirstName: string = '';
  public eLastName: string = '';
  public ePhoneNumber: string = '';
  public eMobileNumber: string = '';
  public eMail: string = '';
  public eBirthday: string = '';
  public eRestriction: string = '';
  public eExercisePeriod: string = '';
  public ihkFileList: any[] = [];
  public gFileList: any[] = [];
  public iToggleState: boolean = false;
  public hToggleState: boolean = false;
  public hHrNummer: string = '';
  public hAmtgericht: string = '';
  public unfallkasse: string = '';
  public aFileList: any[] = [];
  public iaFileList: any[] = [];
  public qnFileList: any[] = [];
  public ecFileList: any[] = [];
  public hpvFileList: any[] = [];
}
