import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IdentityService} from '../../services/identity.service';
import {TextService} from '../../services/text.service';
import {StyleService} from '../../services/style.service';
import {SearchService} from '../../services/search.service';
import {MatSort} from '@angular/material/sort';
import {StyleModel} from '../../models/style.model';
import {environment} from '../../../environments/environment';
import {MatTableDataSource} from '@angular/material/table';
import {EnumLabelResolver, EnumLabelResolverInterface} from '../../constants/enum-label-resolver';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public enumLabelResolver: EnumLabelResolverInterface = EnumLabelResolver;
  items = null;
  DataReady = false;
  expert = null;
  contact = null;
  subdomain;
  identity;
  text;
  currentUrl;
  httpClient;
  public style: StyleModel;
  displayedColumns: string[] = ['company', 'approvedSections', 'address', 'details'];
  dataSource;
  commodities: any = null;
  filter: any = {applicationTypeFilter: 0, search: ''};

  constructor(
    HttpClient: HttpClient,
    Identity: IdentityService,
    TextService: TextService,
    private _styleService: StyleService,
    IdentityService: IdentityService,
    private searchService: SearchService
  ) {
    this.identity = Identity;
    this.httpClient = HttpClient;
    this.text = TextService.text.subscribe(text => {
      this.text = text;
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });

    IdentityService.commodity.subscribe(data => {
      this.commodities = data;
      if (this.commodities != null && this.commodities != undefined) {
        let trueElements = [];
        this.commodities.forEach(data => {
          if (data == true) {
            trueElements.push(data);
          }
        });
      }
    });

    this.currentUrl = window.location.hostname;

    const formData = new FormData();
    formData.append('Subdomain', environment.subdomain);

    HttpClient.post(environment.apiUrl + '/Tenants/GetIdentity', formData).subscribe(
      data => {
        Identity.updateSubdomain(data[0]);
        this.filter.applicationTypeFilter = parseInt(data[8], 10);

        HttpClient.get(environment.apiUrl + '/Texts/GetApplicationTexts').subscribe(data => {
          TextService.updateText(data);
        });

        HttpClient.get<StyleModel>(environment.apiUrl + '/Clients/GetColor').subscribe((style: StyleModel) => {
          this._styleService.updateStyle(style);
          this.style = style;
          const backgroundUrl = environment.apiUrl + '/Clients/GetBackgroundImage/' + this.style['bgi'];
          document.body.style.backgroundImage = `url('${backgroundUrl}')`;

          const logoElement = document.getElementById('logo_image');
          const favicon = document.getElementById('favicon');
          if (style['logo']) {
            logoElement.setAttribute('src', environment.apiUrl + '/Clients/GetBackgroundImage/' + this.style['logo']);
            favicon.setAttribute('href', environment.apiUrl + '/Clients/GetBackgroundImage/' + this.style['logo']);
          }
        });
        this.httpClient.get(environment.apiUrl + '/Applications/ApprovedApplications').subscribe(data => {
          this.items = data;
          this.DataReady = true;
          this.buildElementList(data);
        });
      }
    );
  }

  ngOnInit() {
    this.identity.subdomain.subscribe(data => {
      this.subdomain = data;
    });
  }

  showModal(id) {
    for (let item of this.items) {
      if (item.applicationId === id) {
        switch (item.contactType) {
          case 0:
            this.contact = {
              salutation: item.expert.salutation,
              title: item.expert.title,
              firstName: item.expert.firstName,
              lastName: item.expert.lastName,
              phoneNumber: item.expert.phoneNumber,
              mobileNumber: item.expert.mobileNumber,
              mail: item.expert.mail
            };
            break;
          case 1:
            this.contact = {
              phoneNumber: item.company.phonenumber,
              mail: item.company.email
            };
            break;
          case 2:
            this.contact = {
              salutation: item.owner.salutation,
              title: item.owner.title,
              firstName: item.owner.firstName,
              lastName: item.owner.lastName,
              phoneNumber: item.owner.phoneNumber,
              mobileNumber: item.owner.mobileNumber,
              mail: item.owner.mail
            };
            break;
          case 3:
            this.contact = {
              contractDetails: this.createListOfContractNumberDetails(item),
              contractTitle: item.expert.salutation,
              salutation: item.expert.salutation,
              title: item.expert.title,
              firstName: item.expert.firstName,
              lastName: item.expert.lastName,
              phoneNumber: item.expert.phoneNumber,
              mobileNumber: item.expert.mobileNumber,
              mail: item.expert.mail
            };
            break;
          case null:
            this.contact = null;
            break;
        }
      }
    }

    document.getElementById('modalbtn').click();
  }

  createListOfContractNumberDetails(application) {
    if (application) {
      const powerDetails = application.approvedPower ? {
        contractTitle: "Strom ID:",
        contractNumber: application.contractNumberPower
      } : null;

      const gasDetails = application.approvedGas ? {
        contractTitle: "Gas ID:",
        contractNumber: application.contractNumberGas
      } : null;

      const waterDetails = application.approvedWater ? {
        contractTitle: "Wasser ID:",
        contractNumber: application.contractNumberWater
      } : null;

      const gasAndWaterDetails = application.approvedGasAndWater ? {
        contractTitle: "Gas & Wasser ID:",
        contractNumber: application.contractNumberGasAndWater
      } : null;

      const contractsDetails = [
        powerDetails,
        gasDetails,
        waterDetails,
        gasAndWaterDetails
      ];

      return contractsDetails.filter(cd => cd);
    }

    return null;
  }

  applyFilter() {
    const filterValues = {
      search: this.filter.search.trim().toLowerCase(),
      applicationType: this.filter.applicationTypeFilter
    };

    this.dataSource.filter = JSON.stringify(filterValues);
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.createFilter();
  }

  createFilter(): (data: any, filter: string) => boolean {
    return (data, filter): boolean => {
      const searchTerms = JSON.parse(filter);
      const searchableObj = {
        company: data.company,
        address: data.address,
        zipCode: data.zipCode,
        city: data.city,
        appovedSections: data.appovedSections
      };

      return this.searchService.searchInObj(searchableObj, searchTerms.search) &&
        (searchTerms.applicationType === 0
          || (searchTerms.applicationType === 1 && !data.isGuest)
          || (searchTerms.applicationType === 2 && data.isGuest));
    };
  }

  buildElementList(items) {
    let elements: installateur[] = [];

    items.forEach(i => {
      let element = {
        company: i.company.name,
        address: i.company.street + ' ' + i.company.houseNumber,
        zipCode: i.company.zipCode,
        city: i.company.city,
        details: i.applicationId,
        contactType: i.contactType,
        isGuest: i.isGuest,
        appovedSections: [
          i.approvedGas && 'Gas',
          i.approvedPower && 'Strom',
          i.approvedWater && 'Wasser',
          i.approvedGasAndWater && 'Gas & Wasser'
        ]
          .filter(t => !!t)
          .join(', ')
      };
      elements.push(element);
    });
    this.dataSource = new MatTableDataSource(elements);
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.createFilter();
    this.applyFilter();
  }

  onApplicationTypeFilterChange(value) {
    this.filter.applicationTypeFilter = value;
    this.applyFilter();
  }

  onSearchChange(value: string) {
    this.filter.search = value;
    this.applyFilter();
  }
}

export interface installateur {
  company: string;
  address: string;
  zipCode: string;
  city: string;
  details: number;
}
