import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TenantNotFoundComponent} from "./tenant-not-found.component";

@NgModule({
  declarations: [
    TenantNotFoundComponent
  ],
  exports: [
    TenantNotFoundComponent
  ],
  imports: [
    CommonModule
  ]
})

export class TenantNotFoundModule {}
