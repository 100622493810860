import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})

export class TextService {
  public textData;
  private textArray = new BehaviorSubject(null);
  public text = this.textArray.asObservable();

  public updateText(value) {
    this.textData = value;
    this.textArray.next(this.textData);
  }
}
