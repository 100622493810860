import {IdentityService} from '../../services/identity.service';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {TextService} from 'src/app/services/text.service';
import {StyleService} from 'src/app/services/style.service';
import {StyleModel} from "../../models/style.model";

@Component({
  selector: 'app-installateursausweis',
  templateUrl: './installateursausweis.component.html',
  styleUrls: ['./installateursausweis.component.scss']
})

export class InstallateursausweisComponent implements OnInit {
  fileList: string[] = ['[Noch keine Datei ausgewählt]'];
  message: string = 'Bitte Datei auswählen!';
  submitted: boolean = false;
  valid: boolean = false;
  myRouter: Router;
  aData = null;
  tenantData = null;
  text;
  public style: StyleModel;

  constructor(
    private router: Router,
    private data: DataService,
    TextService: TextService,
    private _styleService: StyleService,
    private identityService: IdentityService
  ) {
    this.aData = data.data;
    this.fileList = this.aData['iaFileList'];
    this.myRouter = this.router;

    TextService.text.subscribe(text => {
      this.text = text;
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });

    this.identityService.tenant.subscribe(e => {
      this.tenantData = e;
    });
  }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  onFileSelected(event) {
    if (this.fileList.length > 0) {
      if (this.fileList[0] == '[Noch keine Datei ausgewählt]') {
        this.fileList = [];
      }
    }

    let i = 0;
    if (event.target.files.length > 0) {
      for (i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].name == 'item' || event.target.files[i].name == 'undefined') {
        } else {
          this.fileList.push(event.target.files[i].name);
        }
      }
    }
    this.data.updateData('iaFileList', this.fileList);

    for (let i = 0; i < event.target.files.length; i++) {
      this.data.installateursausweis_files.push(event.target.files[i]);
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.fileList.length > 0) {
      if (this.fileList[0] == '[Noch keine Datei ausgewählt]') {
        this.valid = false;
      } else {
        const photoUploadEnabled = this.data.data['isGuest']
          ? this.tenantData.guestPhotoUpload
          : this.tenantData.gridInstallerPhotoUpload;
        const nextPage = photoUploadEnabled ? '/ausweisfoto' : '/conclusion';
        this.router.navigate([nextPage]);
      }
    }
  }

  onBack() {
    this.router.navigate(['/expert']);
  }

  deleteFile(index) {
    this.fileList.splice(index, 1);

    var filesToUpload = Array.from(this.data.installateursausweis_files);
    filesToUpload.splice(index, 1);

    this.data.installateursausweis_files = [];

    for (let i = 0; i < filesToUpload.length; i++) {
      this.data.installateursausweis_files.push(filesToUpload[i]);
    }
  }
}
