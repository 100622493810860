import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UnfallkasseComponent} from "./unfallkasse.component";
import {InfoModalModule} from "../../dialogs/info-modal/info-modal.module";

@NgModule({
  declarations: [
    UnfallkasseComponent
  ],
  exports: [
    UnfallkasseComponent
  ],
  imports: [
    CommonModule,
    InfoModalModule
  ]
})

export class UnfallkasseModule {}
