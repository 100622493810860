import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConclusionComponent} from './conclusion.component';
import {InfoModalModule} from '../../dialogs/info-modal/info-modal.module';

@NgModule({
  declarations: [
    ConclusionComponent
  ],
  exports: [
    ConclusionComponent
  ],
  imports: [
    CommonModule,
    InfoModalModule
  ]
})

export class ConclusionModule {}
