import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'tenant-not-found',
  templateUrl: './tenant-not-found.component.html',
  styleUrls: ['./tenant-not-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TenantNotFoundComponent {}
