import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {JwtInterceptor} from './interceptors/jwt.interceptor';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DataService} from './services/data.service';
import {IdentityService} from './services/identity.service';
import {SearchService} from './services/search.service';
import {StyleService} from './services/style.service';
import {TextService} from './services/text.service';
import {WebsiteRefersService} from './services/website-refers.service';
import {ImprintAndDataProtectionLinksModule} from './components/imprint-and-data-protection-links/imprint-and-data-protection-links.module';
import {MatIconModule} from '@angular/material/icon';

registerLocaleData(localeDe, 'de-De', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ImprintAndDataProtectionLinksModule,
    MatIconModule
  ],
  providers: [
    DataService,
    IdentityService,
    SearchService,
    StyleService,
    TextService,
    WebsiteRefersService,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: LOCALE_ID, useValue: 'de-De'},
    {provide: MAT_DATE_LOCALE, useValue: 'de-De'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    provideHttpClient(withInterceptorsFromDi())
  ]
})

export class AppModule {}
