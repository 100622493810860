import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})

export class IdentityService {
  public subdomainData;
  private subArray = new BehaviorSubject(null);
  public subdomain = this.subArray.asObservable();
  public tenantData;
  private tenantDataSubject = new BehaviorSubject(null);
  public tenant = this.tenantDataSubject.asObservable();
  public commodityData;
  private cArray = new BehaviorSubject(null);
  public commodity = this.cArray.asObservable();

  constructor() {}

  public updateSubdomain(value): void {
    this.subdomainData = value;
    this.subArray.next(this.subdomainData);
  }

  public updateCommodities(commodity_array): void {
    this.commodityData = commodity_array;
    this.cArray.next(this.commodityData);
  }

  public updateTenantData(data): void {
    this.tenantData = data;
    this.tenantDataSubject.next(this.tenantData);
  }
}
