import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ApplicationOrGuestComponent} from './pages/application-or-guest/application-or-guest.component';
import {ApplicationOrGuestModule} from './pages/application-or-guest/application-or-guest.module';
import {CompanyComponent} from './pages/company/company.component';
import {CompanyModule} from './pages/company/company.module';
import {WorkshopComponent} from './pages/workshop/workshop.component';
import {WorkshopModule} from './pages/workshop/workshop.module';
import {OwnerComponent} from './pages/owner/owner.component';
import {OwnerModule} from './pages/owner/owner.module';
import {ExpertComponent} from './pages/expert/expert.component';
import {ExpertModule} from './pages/expert/expert.module';
import {IhkComponent} from './pages/ihk/ihk.component';
import {IhkModule} from './pages/ihk/ihk.module';
import {AusweisfotoComponent} from './pages/ausweisfoto/ausweisfoto.component';
import {AusweisfotoModule} from './pages/ausweisfoto/ausweisfoto.module';
import {GewerbeanmeldungComponent} from './pages/gewerbeanmeldung/gewerbeanmeldung.component';
import {GewerbeanmeldungModule} from './pages/gewerbeanmeldung/gewerbeanmeldung.module';
import {HaftpflichtComponent} from './pages/haftpflicht/haftpflicht.component';
import {HaftpflichtModule} from './pages/haftpflicht/haftpflicht.module';
import {HandelsregisterComponent} from './pages/handelsregister/handelsregister.component';
import {HandelsregisterModule} from './pages/handelsregister/handelsregister.module';
import {InnungComponent} from './pages/innung/innung.component';
import {InnungModule} from './pages/innung/innung.module';
import {InstallateursausweisComponent} from './pages/installateursausweis/installateursausweis.component';
import {InstallateursausweisModule} from './pages/installateursausweis/installateursausweis.module';
import {UnfallkasseComponent} from './pages/unfallkasse/unfallkasse.component';
import {UnfallkasseModule} from './pages/unfallkasse/unfallkasse.module';
import {FinalscreenComponent} from './pages/finalscreen/finalscreen.component';
import {FinalscreenModule} from './pages/finalscreen/finalscreen.module';
import {ConclusionComponent} from './pages/conclusion/conclusion.component';
import {ConclusionModule} from './pages/conclusion/conclusion.module';
import {ListComponent} from './pages/list/list.component';
import {ListModule} from './pages/list/list.module';
import {QualificationCertificateComponent} from './pages/qualification-certificate/qualification-certificate.component';
import {QualificationCertificateModule} from './pages/qualification-certificate/qualification-certificate.module';
import {EmploymentContractComponent} from './pages/employment-contract/employment-contract.component';
import {EmploymentContractModule} from './pages/employment-contract/employment-contract.module';
import {TenantNotFoundComponent} from './pages/tenant-not-found/tenant-not-found.component';
import {TenantNotFoundModule} from './pages/tenant-not-found/tenant-not-found.module';

const routes: Routes = [
  {
    path: '',
    component: ApplicationOrGuestComponent
  },
  {
    path: 'company',
    component: CompanyComponent
  },
  {
    path: 'workshop',
    component: WorkshopComponent
  },
  {
    path: 'owner',
    component: OwnerComponent
  },
  {
    path: 'expert',
    component: ExpertComponent
  },
  {
    path: 'ihk',
    component: IhkComponent
  },
  {
    path: 'ausweisfoto',
    component: AusweisfotoComponent
  },
  {
    path: 'gewerbeanmeldung',
    component: GewerbeanmeldungComponent
  },
  {
    path: 'haftpflichtversicherung',
    component: HaftpflichtComponent
  },
  {
    path: 'handelsregister',
    component: HandelsregisterComponent
  },
  {
    path: 'innung',
    component: InnungComponent
  },
  {
    path: 'installateursausweis',
    component: InstallateursausweisComponent
  },
  {
    path: 'unfallkasse',
    component: UnfallkasseComponent
  },
  {
    path: 'final',
    component: FinalscreenComponent
  },
  {
    path: 'conclusion',
    component: ConclusionComponent
  },
  {
    path: 'liste',
    component: ListComponent
  },
  {
    path: 'qualification',
    component: QualificationCertificateComponent
  },
  {
    path: 'employment',
    component: EmploymentContractComponent
  },
  {
    path: 'not-found',
    component: TenantNotFoundComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    ApplicationOrGuestModule,
    CompanyModule,
    WorkshopModule,
    OwnerModule,
    ExpertModule,
    IhkModule,
    AusweisfotoModule,
    GewerbeanmeldungModule,
    HaftpflichtModule,
    HandelsregisterModule,
    InnungModule,
    InstallateursausweisModule,
    UnfallkasseModule,
    FinalscreenModule,
    ConclusionModule,
    ListModule,
    QualificationCertificateModule,
    EmploymentContractModule,
    TenantNotFoundModule
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {}
