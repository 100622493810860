import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {WebsiteRefersModel} from '../models/website-refers.model';

@Injectable({providedIn: 'root'})

export class WebsiteRefersService {
  public websiteRefersData: WebsiteRefersModel;
  private websiteRefers = new BehaviorSubject(null);
  public refers = this.websiteRefers.asObservable();

  public updateWebsiteRefers(value): void {
    this.websiteRefersData = value;
    this.websiteRefers.next(this.websiteRefersData);
  }
}
