<div class="d-flex justify-content-center mt-4">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">{{text['P2_Headline']}}</p>
      <p class="font-3">{{text['P2_Info']}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 wizard-col">
          <div class="form-group form-group-IV" [ngStyle]="{'background-color': switch_button_state ? style['p'] : style['s']}" (click)="toggleFitted()">
            <div class="IV-checkbox form-control form-item shadow-IV clear-background">
              <div *ngIf="fitted_button_state">
                <i class="far fa-check-square switch-button-icon" [ngStyle]="{'color': style['p']}"></i>
                <p class="switch-button-text" [innerHTML]="text['P2_Button_Fitted_1']"></p>
              </div>

              <div *ngIf="!fitted_button_state">
                <i class="far fa-square switch-button-icon" [ngStyle]="{'color': style['p']}"></i>
                <p class="switch-button-text" [innerHTML]="text['P2_Button_Fitted_2']"></p>
              </div>
            </div>
          </div>

          <div class="form-group form-group-IV" [ngStyle]="{'background-color': switch_button_state ? style['p'] : style['s']}" (click)="toggleState()">
            <div class="IV-checkbox form-control form-item shadow-IV clear-background">
              <div *ngIf="switch_button_state">
                <i class="fas fa-arrow-up switch-button-icon"></i>
                <p class="switch-button-text">{{ text['P2_Button_Toggle_2'] }}</p>
              </div>

              <div *ngIf="!switch_button_state">
                <i class="fas fa-arrow-down switch-button-icon"></i>
                <p class="switch-button-text">{{ text['P2_Button_Toggle_1'] }}</p>
              </div>
            </div>
          </div>

          <div *ngIf="switch_button_state">
            <form [formGroup]="workshopForm">
              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="workshopForm.controls['workshopName'].errors && submitted || workshopForm.controls['workshopName'].errors?.['OnlyCharacters']"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Firma*"
                  formControlName="workshopName"
                  required
                  (input)="changeMe('wName', $event)"/>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="workshopForm.controls['street'].errors && submitted || workshopForm.controls['street'].errors?.['OnlyCharacters']"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Straße*"
                  formControlName="street"
                  required
                  (input)="changeMe('wStreet',$event)"/>
                <p class="error-message pull-left" *ngIf="workshopForm.controls['street'].errors?.['OnlyCharacters']">
                  Darf keine Ziffern oder Sonderzeichen enthalten.
                </p>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="workshopForm.controls['houseNumber'].errors && submitted"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Hausnummer"
                  formControlName="houseNumber"
                  (input)="changeMe('wHouseNumber', $event)"/>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="workshopForm.controls['zip'].errors && submitted || workshopForm.controls['zip'].errors?.['ValidZip'] && workshopForm.controls['zip'].dirty"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Postleitzahl*"
                  formControlName="zip"
                  required
                  (input)="changeMe('wZip', $event)"/>
                <p class="error-message pull-left" *ngIf="workshopForm.controls['zip'].errors?.['ValidZip'] && workshopForm.controls['zip'].dirty">
                  Muss aus 5 Ziffern bestehen.
                </p>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="workshopForm.controls['city'].errors && submitted || workshopForm.controls['city'].errors?.['OnlyCharacters']"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Ort*"
                  formControlName="city"
                  required
                  (input)="changeMe('wCity', $event)"/>
                <p class="error-message pull-left" *ngIf="workshopForm.controls['city'].errors?.['OnlyCharacters']">
                  Darf keine Ziffern oder Sonderzeichen enthalten.
                </p>
              </div>
            </form>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button
            *ngIf="style"
            [ngStyle]="{'background-color': style['t']}"
            (click)="onBack()"
            class="btn btn-prev-step btn-full-size btn-big text-bold font-5 shadow-IV">
            {{text['P2_Button_Abort']}}
          </button>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button
            *ngIf="style"
            [ngStyle]="{'background-color': style['p']}"
            class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV"
            (click)="onSubmit()">
            {{text['P2_Button_Next']}}
          </button>
        </div>
      </div>
    </div>

    <div class="progress" *ngIf="!aData['isGuest']">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 8%"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        *ngIf="style"
        [ngStyle]="{'background-color': style['p']}">
      </div>
    </div>

    <div class="progress" *ngIf="aData['isGuest']">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 20%"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        *ngIf="style"
        [ngStyle]="{'background-color': style['p']}">
      </div>
    </div>
  </div>
</div>
