<div class="d-flex justify-content-center mt-4">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">{{text['P4_Headline']}}</p>
      <p class="font-3">{{text['P4_Info']}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 wizard-col">
          <div class="form-group form-group-IV" [ngStyle]="{'background-color': !switch_button_state ? style['p'] : style['s']}" (click)="toggleState()">
            <div class="IV-checkbox form-control form-item shadow-IV clear-background">
              <div *ngIf="switch_button_state">
                <i class="fas fa-arrow-down switch-button-icon"></i>
                <p class="switch-button-text">{{text['P4_Button_Toggle_1']}}</p>
              </div>

              <div *ngIf="!switch_button_state">
                <i class="fas fa-arrow-up switch-button-icon"></i>
                <p class="switch-button-text">{{text['P4_Button_Toggle_2']}}</p>
              </div>
            </div>
          </div>

          <form [formGroup]="expertForm">
            <div *ngIf="!switch_button_state">
              <div class="form-group form-group-IV">
                <select
                  [class.form-item-invalid]="expertForm.controls['salutation'].errors && submitted"
                  class="form-control form-control form-item shadow-IV"
                  formControlName="salutation"
                  required
                  (change)="changeMe('eSalutation', $event)">
                  <option value="0">Anrede*</option>
                  <option *ngFor="let salutation of salutations" [value]="salutation.value">
                    {{salutation.label}}
                  </option>
                </select>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="expertForm.controls['title'].errors && submitted"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Titel"
                  formControlName="title"
                  (input)="changeMe('eTitle', $event)"/>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="expertForm.controls['firstName'].errors && submitted || expertForm.controls['firstName'].errors?.['OnlyCharacters']"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Vorname*"
                  formControlName="firstName"
                  required
                  (input)="changeMe('eFirstName', $event)"/>
                <p class="error-message pull-left" *ngIf="expertForm.controls['firstName'].errors?.['OnlyCharacters']">
                  Darf keine Ziffern oder Sonderzeichen enthalten.
                </p>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="expertForm.controls['lastName'].errors && submitted || expertForm.controls['lastName'].errors?.['OnlyCharacters']"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Nachname*"
                  formControlName="lastName"
                  required
                  (input)="changeMe('eLastName', $event)"/>
                <p class="error-message pull-left" *ngIf="expertForm.controls['lastName'].errors?.['OnlyCharacters']">
                  Darf keine Ziffern oder Sonderzeichen enthalten.
                </p>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="expertForm.controls['phoneNumber'].errors && submitted || expertForm.controls['phoneNumber'].errors?.['OnlyNumbers']"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Telefon*"
                  formControlName="phoneNumber"
                  required
                  (input)="changeMe('ePhoneNumber', $event)"/>
                <p class="error-message pull-left" *ngIf="expertForm.controls['phoneNumber'].errors?.['OnlyNumbers']">
                  Darf nur Ziffern enthalten.
                </p>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="expertForm.controls['mobileNumber'].errors && submitted || expertForm.controls['mobileNumber'].errors?.['OnlyNumbers']"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="Mobil"
                  formControlName="mobileNumber"
                  (input)="changeMe('eMobileNumber', $event)"/>
                <p class="error-message pull-left" *ngIf="expertForm.controls['mobileNumber'].errors?.['OnlyNumbers']">
                  Darf nur Ziffern enthalten.
                </p>
              </div>

              <div class="form-group form-group-IV">
                <input
                  [class.form-item-invalid]="expertForm.controls['mail'].errors && submitted || expertForm.controls['mail'].errors?.['email']"
                  class="form-control form-item shadow-IV"
                  type="text"
                  placeholder="E-Mail*"
                  formControlName="mail"
                  required
                  (input)="changeMe('eMail', $event)"/>
                <p class="error-message pull-left" *ngIf="expertForm.controls['mail'].errors?.['email']">
                  Gültige E-Mail Adresse eingeben.
                </p>
              </div>
            </div>

            <div *ngIf="!aData['isGuest']">
              <div class="form-group form-group-IV date-field">
                <input
                  [class.form-item-invalid]="expertForm.controls['birthday'].errors && submitted"
                  class="form-control form-item shadow-IV"
                  (focus)="birthdayDatepicker.open()"
                  (click)="birthdayDatepicker.open()"
                  [matDatepicker]="birthdayDatepicker"
                  placeholder="Geburtsdatum*"
                  formControlName="birthday"
                  required
                  (dateChange)="changeDate('eBirthday', $event)"/>
                <mat-datepicker-toggle class="datepicker-toggle" [for]="birthdayDatepicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #birthdayDatepicker></mat-datepicker>
              </div>

              <div class="form-group-IV checkbox-field">
                <mat-checkbox color="primary" formControlName="areExerciseFieldsShown" (change)="onExerciseFieldsVisibilityChange()">
                  Es besteht eine Ausübungsbeschränkung
                </mat-checkbox>
              </div>

              <ng-container *ngIf="expertForm.get('areExerciseFieldsShown').value">
                <div class="form-group form-group-IV">
                  <input
                    [class.form-item-invalid]="expertForm.controls['restiction'].errors && submitted"
                    class="form-control form-item shadow-IV"
                    type="text"
                    placeholder="Ausübungsbeschränkung"
                    formControlName="restiction"
                    (input)="changeMe('eRestriction', $event)"/>
                </div>

                <div class="form-group form-group-IV date-field">
                  <input
                    class="form-control form-item shadow-IV"
                    [class.form-item-invalid]="expertForm.controls['exercisePeriod'].errors && submitted"
                    placeholder="Ausübungsfrist"
                    formControlName="exercisePeriod"
                    (focus)="exercisePeriodDatepicker.open()" (click)="exercisePeriodDatepicker.open()"
                    [matDatepicker]="exercisePeriodDatepicker"
                    (dateChange)="changeDate('eExercisePeriod', $event)"/>
                  <mat-datepicker-toggle class="datepicker-toggle" [for]="exercisePeriodDatepicker" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #exercisePeriodDatepicker></mat-datepicker>
                </div>
              </ng-container>
            </div>
          </form>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button
            *ngIf="style"
            [ngStyle]="{'background-color': style['t']}"
            (click)="onBack()"
            class="btn btn-prev-step btn-full-size btn-big text-bold font-5 shadow-IV">
            {{text['P4_Button_Abort']}}
          </button>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button
            *ngIf="style"
            [ngStyle]="{'background-color': style['p']}"
            class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV"
            (click)="onSubmit()">
            {{text['P4_Button_Next']}}
          </button>
        </div>
      </div>
    </div>

    <div class="progress" *ngIf="!aData['isGuest']">
      <div
        *ngIf="style"
        class="progress-bar"
        role="progressbar"
        style="width: 24%"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{'background-color': style['p']}">
      </div>
    </div>

    <div class="progress" *ngIf="aData['isGuest']">
      <div
        *ngIf="style"
        class="progress-bar"
        role="progressbar"
        style="width: 60%"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{'background-color': style['p']}">
      </div>
    </div>
  </div>
</div>
