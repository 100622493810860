import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {HttpClient, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {TextService} from '../../services/text.service';
import {StyleService} from '../../services/style.service';
import {IdentityService} from '../../services/identity.service';
import {StyleModel} from '../../models/style.model';
import {environment} from '../../../environments/environment';
import {EnumLabelResolver, EnumLabelResolverInterface} from '../../constants/enum-label-resolver';

@Component({
  selector: 'app-conclusion',
  templateUrl: './conclusion.component.html',
  styleUrls: ['./conclusion.component.scss']
})

export class ConclusionComponent implements OnInit {
  public enumLabelResolver: EnumLabelResolverInterface = EnumLabelResolver;
  myRouter: Router;
  message: string = 'Ihre Angaben im Überblick';
  aData = null;
  submitted: boolean = false;
  progress: number = 0;
  upload_success = false;
  Button_Message: string;
  companyToggle: boolean = true;
  workshopToggle: boolean = true;
  ownerToggle: boolean = true;
  expertToggle: boolean = true;
  ihkToggle: boolean = true;
  qnToggle: boolean = true;
  gewerbeToggle: boolean = true;
  innungToggle: boolean = true;
  hrToggle: boolean = true;
  unfallToggle: boolean = true;
  hpvToggle: boolean = true;
  fotoToggle: boolean = true;
  iaToggle: boolean = true;
  ecToggle: boolean = true;
  text;
  public style: StyleModel;
  subdomain;
  tenantData = null;

  constructor(
    private router: Router,
    private data: DataService,
    private http: HttpClient,
    TextService: TextService,
    private _styleService: StyleService,
    private identityService: IdentityService
  ) {
    this.aData = data.data;

    this.myRouter = this.router;

    this.identityService.subdomain.subscribe(data => {
      this.subdomain = data;
    });

    this.identityService.tenant.subscribe(e => {
      this.tenantData = e;
    });

    TextService.text.subscribe(text => {
      this.text = text;
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });

    this.Button_Message = this.text['P14_Button_Send_1'];
  }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;
      this.Button_Message = this.text['P14_Button_Send_3'];

      const formData = new FormData();

      formData.append('IsGuest', this.aData['isGuest']);
      formData.append('AppliedPower', this.aData['commodities'][0]);
      formData.append('AppliedGas', this.aData['commodities'][1]);
      formData.append('AppliedWater', this.aData['commodities'][2]);
      formData.append('AppliedGasAndWater', this.aData['commodities'][3]);

      formData.append('Cname', this.aData['cName']);
      formData.append('Cstreet', this.aData['cStreet']);
      formData.append('Chousenumber', this.aData['cHouseNumber']);
      formData.append('Czip', this.aData['cZip']);
      formData.append('Ccity', this.aData['cCity']);
      formData.append('Ccountry', this.aData['cCountry']);
      formData.append('CphoneNumber', this.aData['cPhoneNumber']);
      formData.append('Cmail', this.aData['cMail']);

      formData.append('Wtoggle', this.aData['wToggle']);
      formData.append('Wname', this.aData['wName']);
      formData.append('Wstreet', this.aData['wStreet']);
      formData.append('Whousenumber', this.aData['wHouseNumber']);
      formData.append('Wzip', this.aData['wZip']);
      formData.append('Wcity', this.aData['wCity']);
      formData.append('Wfitted', this.aData['wFitted']);

      formData.append('Osalutation', this.aData['oSalutation']);
      formData.append('Otitle', this.aData['oTitle']);
      formData.append('Ofirstname', this.aData['oFirstName']);
      formData.append('Olastname', this.aData['oLastName']);
      formData.append('Ophonenumber', this.aData['oPhoneNumber']);
      formData.append('Omobilenumber', this.aData['oMobileNumber']);
      formData.append('Omail', this.aData['oMail']);

      formData.append('Etogglestate', this.aData['eToggleState']);
      formData.append('Esalutation', this.aData['eSalutation']);
      formData.append('Etitle', this.aData['eTitle']);
      formData.append('Efirstname', this.aData['eFirstName']);
      formData.append('Elastname', this.aData['eLastName']);
      formData.append('Ephonenumber', this.aData['ePhoneNumber']);
      formData.append('Emobilenumber', this.aData['eMobileNumber']);
      formData.append('Email', this.aData['eMail']);
      formData.append('Ebirthday', this.aData['eBirthday']);
      formData.append('Erestriction', this.aData['eRestriction']);
      formData.append('EexercisePeriod', this.aData['eExercisePeriod']);

      if (this.data.ec_files != null) {
        for (let i = 0; i < this.data.ec_files.length; i++) {
          formData.append('Ec', this.data.ec_files[i]);
        }
      }

      if (this.data.qn_files != null) {
        for (let i = 0; i < this.data.qn_files.length; i++) {
          console.log(this.data.qn_files[i]);
          formData.append('Qn', this.data.qn_files[i]);
        }
      }

      if (this.data.hpv_files != null) {
        for (let i = 0; i < this.data.hpv_files.length; i++) {
          formData.append('Hpv', this.data.hpv_files[i]);
        }
      }

      if (this.data.ihk_files != null) {
        for (let i = 0; i < this.data.ihk_files.length; i++) {
          console.log(this.data.ihk_files[i]);
          formData.append('Ihk', this.data.ihk_files[i]);
        }
      }

      if (this.data.gewerbeanmeldung_files != null) {
        for (let i = 0; i < this.data.gewerbeanmeldung_files.length; i++) {
          formData.append('Gewerbeanmeldung', this.data.gewerbeanmeldung_files[i]);
        }
      }

      formData.append('Itogglestate', this.aData['iToggleState']);

      formData.append('Htogglestate', this.aData['hToggleState']);
      formData.append('Hhrnummer', this.aData['hHrNummer']);
      formData.append('Hamtgericht', this.aData['hAmtgericht']);

      formData.append('Unfallkasse', this.aData['unfallkasse']);

      if (this.data.installateursausweis_files != null) {
        for (let i = 0; i < this.data.installateursausweis_files.length; i++) {
          formData.append('Installateursausweis', this.data.installateursausweis_files[i]);
        }
      }

      if (this.data.ausweisfotos_files != null) {
        for (let i = 0; i < this.data.ausweisfotos_files.length; i++) {
          formData.append('Ausweisfoto', this.data.ausweisfotos_files[i]);
        }
      }

      this.http
        .post(environment.apiUrl + '/Applications', formData, {reportProgress: true, observe: 'events'})
        .subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              this.Button_Message = this.text['P14_Button_Send_3'].replace('[replace]', this.progress);
            } else if (event.type === HttpEventType.Response) {
              if (event.status == 500 || event.status == 404 || event.status == 400) {
                this.upload_success = false;
                this.Button_Message = this.text['P14_Button_Send_2'];
              } else {
                this.upload_success = true;
                this.Button_Message = this.text['P14_Button_Send_4'];
                this.router.navigate(['/final']);
              }
            }
          },
          (error: HttpErrorResponse) => {
            this.upload_success = false;
            this.Button_Message = this.text['P14_Button_Send_2'];
          }
        );
    } else if (this.submitted && this.upload_success) {
      this.router.navigate(['/final']);
    }
  }

  onBack() {
    const isGuestApplication = this.aData['isGuest'];
    const photoUploadEnabled = isGuestApplication ? this.tenantData.guestPhotoUpload : this.tenantData.gridInstallerPhotoUpload;

    let previousPage = isGuestApplication ? '/installateursausweis' : '/haftpflichtversicherung';
    if (photoUploadEnabled) {
      previousPage = '/ausweisfoto';
    }
    this.router.navigate([previousPage]);
  }

  onCollapseableClick(index) {
    switch (index) {
      case 1:
        this.companyToggle = !this.companyToggle;
        break;
      case 2:
        this.workshopToggle = !this.workshopToggle;
        break;
      case 3:
        this.ownerToggle = !this.ownerToggle;
        break;
      case 4:
        this.expertToggle = !this.expertToggle;
        break;
      case 5:
        this.ihkToggle = !this.ihkToggle;
        break;
      case 6:
        this.gewerbeToggle = !this.gewerbeToggle;
        break;
      case 7:
        this.innungToggle = !this.innungToggle;
        break;
      case 8:
        this.hrToggle = !this.hrToggle;
        break;
      case 9:
        this.unfallToggle = !this.unfallToggle;
        break;
      case 10:
        this.hpvToggle = !this.hpvToggle;
        break;
      case 11:
        this.fotoToggle = !this.fotoToggle;
        break;
      case 12:
        this.iaToggle = !this.iaToggle;
        break;
      case 13:
        this.qnToggle = !this.qnToggle;
        break;
      case 14:
        this.ecToggle = !this.ecToggle;
        break;
    }
  }
}
