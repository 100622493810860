<div class="d-flex justify-content-center mt-4">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">{{text['P1_Headline']}}</p>
      <p class="font-3">{{text['P1_Info']}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 wizard-col">
          <form [formGroup]="form">
            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['companyName'].errors && submitted || form.controls['companyName'].errors?.['OnlyCharacters']"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Firma*"
                formControlName="companyName"
                required
                (input)="changeMe('cName', $event)"/>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['street'].errors && submitted || form.controls['street'].errors?.['OnlyCharacters']"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Straße*"
                formControlName="street"
                required
                (input)="changeMe('cStreet',$event)"/>
              <p class="error-message pull-left" *ngIf="form.controls['street'].errors?.['OnlyCharacters']">
                Darf keine Ziffern oder Sonderzeichen enthalten.
              </p>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['houseNumber'].errors && submitted"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Hausnummer"
                formControlName="houseNumber"
                (input)="changeMe('cHouseNumber', $event)"/>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['zip'].errors && submitted || form.controls['zip'].errors?.['ValidZip'] && form.controls['zip'].dirty"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Postleitzahl*"
                formControlName="zip"
                required
                (input)="changeMe('cZip', $event)"/>
              <p class="error-message pull-left" *ngIf="form.controls['zip'].errors?.['ValidZip'] && form.controls['zip'].dirty">
                Muss aus 5 Ziffern bestehen.
              </p>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['city'].errors && submitted || form.controls['city'].errors?.['OnlyCharacters']"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Ort*"
                formControlName="city"
                required
                (input)="changeMe('cCity', $event)"/>
              <p class="error-message pull-left" *ngIf="form.controls['city'].errors?.['OnlyCharacters']">
                Darf keine Ziffern oder Sonderzeichen enthalten.
              </p>
            </div>

            <div class="form-group form-group-IV">
              <select [value]="selectedCountry" class="form-control form-control form-item shadow-IV country-select" formControlName="country" (change)="changeMe('cCountry',$event)">
                <option *ngFor="let country of countries" [value]="country">
                  {{country}}
                </option>
              </select>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['phoneNumber'].errors && submitted || form.controls['phoneNumber'].errors?.['OnlyNumbers']"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="Telefon*"
                formControlName="phoneNumber"
                required
                (input)="changeMe('cPhoneNumber', $event)"/>
              <p class="error-message pull-left" *ngIf="form.controls['phoneNumber'].errors?.['OnlyNumbers']">
                Darf nur Ziffern enthalten.
              </p>
            </div>

            <div class="form-group form-group-IV">
              <input
                [class.form-item-invalid]="form.controls['mail'].errors && submitted || form.controls['mail'].errors?.['email']"
                class="form-control form-item shadow-IV"
                type="text"
                placeholder="E-Mail*"
                formControlName="mail"
                required
                (input)="changeMe('cMail', $event)"/>
              <p class="error-message pull-left" *ngIf="form.controls['mail'].errors?.['email']">
                Gültige E-Mail Adresse eingeben.
              </p>
            </div>
          </form>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button
            *ngIf="style"
            [ngStyle]="{'background-color': style['t']}"
            (click)="onBack()"
            class="btn btn-prev-step btn-full-size btn-big text-bold font-5 shadow-IV">
            {{text['P1_Button_Abort']}}
          </button>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button
            *ngIf="style"
            [ngStyle]="{'background-color': style['p']}"
            class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV"
            (click)="onSubmit()">
            {{text['P1_Button_Next']}}
          </button>
        </div>
      </div>
    </div>

    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 2%"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        *ngIf="style"
        [ngStyle]="{'background-color': style['p']}">
      </div>
    </div>
  </div>
</div>
