<button
  id="modalbtn"
  type="button"
  class="btn btn-info btn-lg"
  data-toggle="modal"
  data-target="#ModalDataProtection"
  [hidden]="true">
  Open Modal
</button>

<div id="ModalDataProtection" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog">
    <div *ngIf="text" class="modal-content">
      <div class="modal-header text-center">
        <p class="font-2 text-center">{{text['P0_M_Headline']}}</p>
      </div>

      <div class="modal-body">
        <span>{{text['P0_M_Info_H1']}}</span>
        <span [innerHTML]="text['P0_M_DSE_Link']"></span>
        <span>{{text['P0_M_Info_H2']}}</span>

        <div>
          <div class="row">
            <div class="col-6">
              <button *ngIf="style" [ngStyle]="{'background-color': style['t']}" type="button" class="btn btn-prev-step btn-big btn-full-size text-bold font-5 shadow-IV mt-5" data-dismiss="modal" (click)="DataProtectionDeclined()">{{ text['P0_M_Button_Abort'] }}</button>
            </div>

            <div class="col-6">
              <button *ngIf="style" [ngStyle]="{'background-color': style['p']}" type="button" class="btn btn-next-step btn-big btn-full-size text-bold font-5 shadow-IV mt-5" data-dismiss="modal" (click)="DataProtectionConfirmed()">{{ text['P0_M_Button_Next'] }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="ModalInfoNet" class="modal fade" role="dialog" data-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="text">
      <div class="modal-header text-center">
        <p class="font-2 text-center">{{text['P0_CN_Headline']}}</p>
      </div>

      <div class="modal-body">
        <span>{{text['P0_CN_Info']}}</span>

        <div class="mt-2">
          <ul>
            <li>{{text['P0_CN_CB_1']}}</li>
            <li>{{text['P0_CN_CB_2']}}</li>
            <li>{{text['P0_CN_CB_3']}}</li>
            <li>{{text['P0_CN_CB_4']}}</li>
            <li>{{text['P0_CN_CB_5']}}</li>
            <li>{{text['P0_CN_CB_6']}}</li>
            <li>{{text['P0_CN_CB_7']}}</li>
          </ul>
        </div>

        <div class="mt-2">
          <span>{{ text['P0_CN_Bot_Info'] }}</span>
        </div>

        <div>
          <div class="row">
            <div class="col-sm-12 col-md-12 mt-2 wizard-col">
              <button *ngIf="style" [ngStyle]="{'background-color': style['p']}" (click)="onClickInstallatuer()" data-toggle="modal" data-target="#ModalInfoNet" data-dismiss="modal" class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV">{{ text['P0_CN_Button'] }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="ModalInfoGuest" class="modal fade" role="dialog" data-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="text">

      <div class="modal-header text-center">
        <p class="font-2 text-center">{{text['P0_CG_Headline']}}</p>
      </div>

      <div class="modal-body">
        <span>{{text['P0_CG_Info']}}</span>

        <div class="mt-2">
          <ul>
            <li><input type="checkbox" class="mr-1" />Installateursausweis</li>
          </ul>
        </div>

        <div class="mt-2">
          <span>{{ text['P0_CG_Bot_Info'] }}</span>
        </div>

        <div>
          <div class="row">
            <div class="col-sm-12 col-md-12 mt-2 wizard-col">
              <button *ngIf="style" [ngStyle]="{'background-color': style['p']}" (click)="onClickGuest()" data-toggle="modal" data-target="#ModalInfoGuest" data-dismiss="modal" class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV">{{ text['P0_CG_Button'] }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center mt-4" *ngIf="text">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">{{text['P0_S_Headline']}}</p>
      <p class="font-3">{{text['P0_S_Info']}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 wizard-col" *ngIf="commoditySelectable">
          <div class="card text-center card-select-parent shadow-IV">
            <div class="card-body card-select-item d-flex flex-column">
              <p class="card-title font-4">Für welche Sparten möchten Sie sich anmelden?</p>
              <div class="form-group form-group-IV commodity_button" [ngStyle]="{'background-color': power_button_state ? style['p'] : style['s']}" (click)="changeCommodity('power')" *ngIf="commodities[0]">
                <div class="IV-checkbox form-control form-item shadow-IV clear-background">
                  <div *ngIf="power_button_state">
                    <i class="far fa-check-square switch-button-icon" [ngStyle]="{'color': style['p']}"></i>
                    <p class="switch-button-text">Strom</p>
                  </div>

                  <div *ngIf="!power_button_state">
                    <i class="far fa-square switch-button-icon" [ngStyle]="{'color': style['p']}"></i>
                    <p class="switch-button-text">Strom</p>
                  </div>
                </div>
              </div>

              <div class="form-group form-group-IV commodity_button" [ngStyle]="{'background-color': gas_button_state ? style['p'] : style['s']}" (click)="changeCommodity('gas')" *ngIf="commodities[1]">
                <div class="IV-checkbox form-control form-item shadow-IV clear-background">
                  <div *ngIf="gas_button_state">
                    <i class="far fa-check-square switch-button-icon" [ngStyle]="{'color': style['p']}"></i>
                    <p class="switch-button-text">Gas</p>
                  </div>

                  <div *ngIf="!gas_button_state">
                    <i class="far fa-square switch-button-icon" [ngStyle]="{'color': style['p']}"></i>
                    <p class="switch-button-text">Gas</p>
                  </div>
                </div>
              </div>

              <div class="form-group form-group-IV commodity_button" [ngStyle]="{'background-color': water_button_state ? style['p'] : style['s']}" (click)="changeCommodity('water')" *ngIf="commodities[2]">
                <div class="IV-checkbox form-control form-item shadow-IV clear-background">
                  <div *ngIf="water_button_state">
                    <i class="far fa-check-square switch-button-icon" [ngStyle]="{'color': style['p']}"></i>
                    <p class="switch-button-text">Wasser</p>
                  </div>

                  <div *ngIf="!water_button_state">
                    <i class="far fa-square switch-button-icon" [ngStyle]="{'color': style['p']}"></i>
                    <p class="switch-button-text">Wasser</p>
                  </div>
                </div>
              </div>

              <div class="form-group form-group-IV commodity_button" [ngStyle]="{'background-color': gasWater_button_state ? style['p'] : style['s']}" (click)="changeCommodity('gasWater')" *ngIf="commodities[3] && !(commodities[1] && commodities[2])">
                <div class="IV-checkbox form-control form-item shadow-IV clear-background">
                  <div *ngIf="gasWater_button_state">
                    <i class="far fa-check-square switch-button-icon" [ngStyle]="{'color': style['p']}"></i>
                    <p class="switch-button-text">Gas & Wasser</p>
                  </div>

                  <div *ngIf="!gasWater_button_state">
                    <i class="far fa-square switch-button-icon" [ngStyle]="{'color': style['p']}"></i>
                    <p class="switch-button-text">Gas & Wasser</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 mt-2 wizard-col" *ngIf="commoditySelected">
          <div class="col-sm-12 col-md-12 mt-2 wizard-col">
            <div class="card text-center card-select-parent shadow-IV">
              <div class="card-body card-select-item d-flex flex-column">
                <p class="card-title font-4">{{ text['P0_NK_Headline'] }}</p>
                <p class="card-text card-select-item-element font-5 ">{{ text['P0_NK_Info'] }}</p>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 mt-2 wizard-col">
            <button *ngIf="style" [ngStyle]="{'background-color': style['p']}" data-toggle="modal" data-target="#ModalInfoNet" class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV">{{ text['P0_NK_Button'] }}</button>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 mt-2 wizard-col" *ngIf="commoditySelected">
          <div class="col-sm-12 col-md-12 mt-2 wizard-col">
            <div class="card text-center card-select-parent shadow-IV">
              <div class="card-body card-select-item d-flex flex-column">
                <p class="card-title font-4">{{ text['P0_GK_Headline'] }}</p>
                <p class="card-text card-select-item-element font-5">{{ text['P0_GK_Info'] }}</p>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 mt-2 wizard-col">
              <button *ngIf="style" [ngStyle]="{'background-color': style['p']}" data-toggle="modal" data-target="#ModalInfoGuest" class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV">
                {{text['P0_GK_Button']}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
