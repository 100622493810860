import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyComponent} from './company.component';
import {ReactiveFormsModule} from '@angular/forms';
import {InfoModalModule} from '../../dialogs/info-modal/info-modal.module';

@NgModule({
  declarations: [
    CompanyComponent
  ],
  exports: [
    CompanyComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InfoModalModule
  ]
})

export class CompanyModule {}
