<div class="d-flex justify-content-center mt-4">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">{{text['P15_Headline']}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 wizard-col">
          <div>
            <p class="font-5">{{text['P15_Infotext_1']}}</p>
          </div>

          <div>
            <p class="font-5">{{text['P15_Infotext_2']}}</p>
            <p class="font-4">{{aData.oMail}}</p>
          </div>

          <div>
            <p class="font-4">{{text['P15_Infotext_3']}}</p>
            <span class="font-5">{{text['P15_Anschrift_Firma']}}</span><br />
            <span class="font-5">{{text['P15_Anschrift_Straße']}}</span><br />
            <span class="font-5">{{text['P15_Anschrift_Ort']}}</span><br />
            <span class="font-5">{{text['P15_Anschrift_Telefon']}}</span><br />
            <span class="font-5">{{text['P15_Anschrift_Fax']}}</span><br />
          </div>
        </div>

        <div class="col-sm-12 col-md-12 wizard-col mt-5">
          <button *ngIf="style" [ngStyle]="{'background-color': style['p']}" class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV" (click)="finishButtonClick()">{{ text['P15_Button_Abort'] }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
