import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InstallateursausweisComponent} from "./installateursausweis.component";

@NgModule({
  declarations: [
    InstallateursausweisComponent
  ],
  exports: [
    InstallateursausweisComponent
  ],
  imports: [
    CommonModule
  ]
})

export class InstallateursausweisModule {}
