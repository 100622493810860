import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HandelsregisterComponent} from "./handelsregister.component";
import {InfoModalModule} from "../../dialogs/info-modal/info-modal.module";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    HandelsregisterComponent
  ],
  exports: [
    HandelsregisterComponent
  ],
  imports: [
    CommonModule,
    InfoModalModule,
    ReactiveFormsModule
  ]
})

export class HandelsregisterModule {}
