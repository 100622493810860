<div class="d-flex justify-content-center mt-4">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">Ausweisfoto</p>
      <p class="font-3">{{message}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 wizard-col">
          <div class="form-group form-group-IV">
            <div
              [class.form-item-invalid]="!valid && submitted"
              class="upload-btn-wrapper form-group form-group-IV"
              *ngIf="style"
              [ngStyle]="{ color: style['p'] }">
              <button
                class="form-control form-item shadow-IV giant-button"
                *ngIf="style"
                [ngStyle]="{color: style['p']}">
                <i class="fas fa-file-upload giant-button-icon" *ngIf="style" [ngStyle]="{color: style['p']}"></i>
              </button>

              <input type="file" accept="image/png,image/jpeg" name="myfile" (change)="onFileSelected($event)"/>
            </div>
          </div>

          <div class="form-group form-group-IV">
            <div [class.form-item-invalid]="!valid && submitted" class="fileList text-center form-control form-item shadow-IV">
              <ul class="mt-1">
                <li *ngFor="let file of fileList">{{file}}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button
            *ngIf="style"
            [ngStyle]="{'background-color': style['t']}"
            (click)="onBack()"
            class="btn btn-prev-step btn-full-size btn-big text-bold font-5 shadow-IV">
            {{text['P4_Button_Abort']}}
          </button>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button
            *ngIf="style"
            [ngStyle]="{'background-color': style['p']}"
            class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV"
            (click)="onSubmit()">
            {{text['P4_Button_Next']}}
          </button>
        </div>
      </div>
    </div>

    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        style="width: 95%"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        *ngIf="style"
        [ngStyle]="{'background-color': style['p']}">
      </div>
    </div>
  </div>
</div>
