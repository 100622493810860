import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AusweisfotoComponent} from "./ausweisfoto.component";
import {MessageDialogModule} from '../../dialogs/message-dialog/message-dialog.module';
import {InfoModalModule} from '../../dialogs/info-modal/info-modal.module';

@NgModule({
  declarations: [
    AusweisfotoComponent
  ],
  exports: [
    AusweisfotoComponent
  ],
  imports: [
    CommonModule,
    MessageDialogModule,
    InfoModalModule
  ]
})

export class AusweisfotoModule {}
