import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmploymentContractComponent} from "./employment-contract.component";
import {InfoModalModule} from "../../dialogs/info-modal/info-modal.module";

@NgModule({
  declarations: [
    EmploymentContractComponent
  ],
  exports: [
    EmploymentContractComponent
  ],
  imports: [
    CommonModule,
    InfoModalModule
  ]
})

export class EmploymentContractModule {}
