import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApplicationModel} from '../models/application.model';

@Injectable({providedIn: 'root'})

export class DataService {
  public data: ApplicationModel = new ApplicationModel();
  private dataArray = new BehaviorSubject(this.data);

  private _ausweisfotos_files: File[] = [];
  get ausweisfotos_files(): File[] {
    return this._ausweisfotos_files;
  }

  set ausweisfotos_files(new_ausweisfotos_files: File[]) {
    this._ausweisfotos_files = new_ausweisfotos_files;
  }

  private _installateursausweis_files: File[] = [];
  get installateursausweis_files(): File[] {
    return this._installateursausweis_files;
  }

  set installateursausweis_files(new_installateursausweis_files: File[]) {
    this._installateursausweis_files = new_installateursausweis_files;
  }

  private _ihk_files: File[] = [];
  get ihk_files(): File[] {
    return this._ihk_files;
  }

  set ihk_files(new_ihk_files: File[]) {
    this._ihk_files = new_ihk_files;
  }

  private _gewerbeanmeldung_files: File[] = [];
  get gewerbeanmeldung_files(): File[] {
    return this._gewerbeanmeldung_files;
  }

  set gewerbeanmeldung_files(new_gewerbeanmeldung_files: File[]) {
    this._gewerbeanmeldung_files = new_gewerbeanmeldung_files;
  }

  private _qn_files: File[] = [];
  get qn_files(): File[] {
    return this._qn_files;
  }

  set qn_files(new_qn_files: File[]) {
    this._qn_files = new_qn_files;
  }

  private _ec_files: File[] = [];
  get ec_files(): File[] {
    return this._ec_files;
  }

  set ec_files(new_ec_files: File[]) {
    this._ec_files = new_ec_files;
  }

  private _hpv_files: File[] = [];
  get hpv_files(): File[] {
    return this._hpv_files;
  }

  set hpv_files(new_hpv_files: File[]) {
    this._hpv_files = new_hpv_files;
  }

  public updateData(key: string, value: any): void {
    this.data[key] = value;
    this.dataArray.next(this.data);
  }
}
