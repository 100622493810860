import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkshopComponent} from './workshop.component';
import {ReactiveFormsModule} from '@angular/forms';
import {InfoModalModule} from '../../dialogs/info-modal/info-modal.module';

@NgModule({
  declarations: [
    WorkshopComponent
  ],
  exports: [
    WorkshopComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InfoModalModule
  ]
})

export class WorkshopModule {}
