import {SalutationEnum} from '../enums/salutation.enum';

export interface EnumLabelResolverInterface {
  salutation: {[key: string]: string};
}

export const EnumLabelResolver: EnumLabelResolverInterface = {
  salutation: {
    [SalutationEnum.Woman]: 'Frau',
    [SalutationEnum.Man]: 'Herr',
    [SalutationEnum.Divers]: 'Divers'
  }
}
