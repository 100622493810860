import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {OnlyCharacters} from '../../validators/only_characters';
import {ValidZip} from '../../validators/zip';
import {TextService} from '../../services/text.service';
import {StyleService} from '../../services/style.service';
import {IdentityService} from '../../services/identity.service';
import {environment} from '../../../environments/environment';
import {StyleModel} from '../../models/style.model';

@Component({
  selector: 'app-workshop',
  templateUrl: './workshop.component.html',
  styleUrls: ['./workshop.component.scss']
})

export class WorkshopComponent implements OnInit {
  switch_button_state: boolean = false;
  fitted_button_state: boolean = false;
  workshopForm: UntypedFormGroup;
  submitted: boolean = false;
  myRouter: Router;
  message: string = 'Angaben zu Ihrer Werkstatt (sofern vom Unternehmenssitz abweichend)';
  aData = null;
  text;
  public style: StyleModel;
  link;
  subdomain;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private data: DataService,
    TextService: TextService,
    private _styleService: StyleService,
    IdentityService: IdentityService
  ) {
    this.aData = data.data;
    this.switch_button_state = this.aData['wToggleState'];
    this.workshopForm = this.fb.group({
      workshopName: [this.aData['wName'], [Validators.required]],
      street: [this.aData['wStreet'], [Validators.required, OnlyCharacters]],
      houseNumber: [this.aData['wHouseNumber']],
      zip: [this.aData['wZip'], [Validators.required, ValidZip]],
      city: [this.aData['wCity'], [Validators.required, OnlyCharacters]]
    });

    this.myRouter = this.router;

    IdentityService.subdomain.subscribe(data => {
      this.subdomain = data;
    });

    TextService.text.subscribe(text => {
      if (text) {
        this.text = text;
        this.text['P2_Button_Fitted_1'] = this.text['P2_Button_Fitted_1'].replace(/href='.*'/gi, this.link);
        this.text['P2_Button_Fitted_2'] = this.text['P2_Button_Fitted_2'].replace(/href='.*'/gi, this.link);
      }
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
      this.link = "href='" + environment.apiUrl + '/Clients/GetTenantFile/' + this.style['leaflet'] + "'";
      this.text['P2_Button_Fitted_1'] = this.text['P2_Button_Fitted_1'].replace(/href='.*'/gi, this.link);
      this.text['P2_Button_Fitted_2'] = this.text['P2_Button_Fitted_2'].replace(/href='.*'/gi, this.link);
    });
  }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.text['P2_Button_Fitted_1'] = this.text['P2_Button_Fitted_1'].replace(/href='.*'/gi, this.link);
    this.text['P2_Button_Fitted_2'] = this.text['P2_Button_Fitted_2'].replace(/href='.*'/gi, this.link);
  }

  onSubmit() {
    this.submitted = true;

    if (this.workshopForm.valid || !this.switch_button_state) {
      this.router.navigate(['/owner']);
    }
  }

  changeMe(control: string, event) {
    this.data.updateData(control, event.target.value);
  }

  toggleState() {
    this.switch_button_state = !this.switch_button_state;
    this.data.updateData('wToggle', this.switch_button_state);
  }

  toggleFitted() {
    this.fitted_button_state = !this.fitted_button_state;
    this.data.updateData('wFitted', this.fitted_button_state);
  }

  onBack() {
    this.router.navigate(['/company']);
  }
}
