import {AbstractControl} from '@angular/forms';

export function ValidZip(control: AbstractControl) {
  const regex: RegExp = /^[0-9]*$/gm;
  const forbidden = regex.test(control.value);

  if (forbidden) {
    if (control.value.length == 5) {
      return null;
    } else {
      return {ValidZip: true};
    }
  } else {
    return {ValidZip: true};
  }
}
