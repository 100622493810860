<div class="d-flex justify-content-center mt-4">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">{{text['P11_Headline']}}</p>
      <p class="font-3">{{text['P11_Info']}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 wizard-col">
          <div class="form-group form-group-IV">
            <input class="form-control form-item shadow-IV" type="text" placeholder="Unfallkasse" value="{{value}}" (input)="changeMe('unfallkasse', $event)"/>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button *ngIf="style" [ngStyle]="{'background-color': style['t']}" (click)="onBack()" class="btn btn-prev-step btn-full-size btn-big text-bold font-5 shadow-IV">{{ text['P11_Button_Abort'] }}</button>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button *ngIf="style" [ngStyle]="{'background-color': style['p']}" class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV" (click)="onSubmit()">{{ text['P11_Button_Next'] }}</button>
        </div>
      </div>
    </div>

    <div class="progress">
      <div class="progress-bar" role="progressbar" style="width: 86%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="style" [ngStyle]="{'background-color': style['p']}"></div>
    </div>
  </div>
</div>
