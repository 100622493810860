import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, FormGroup, Validators} from '@angular/forms'
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {OnlyNumbers} from '../../validators/only_numbers';
import {OnlyCharacters} from '../../validators/only_characters';
import {TextService} from '../../services/text.service';
import {StyleService} from '../../services/style.service';
import {StyleModel} from '../../models/style.model';
import {SelectOptionInterface} from '../../interfaces/select-option.interface';
import {Salutations} from '../../constants/select-options';

@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ExpertComponent implements OnInit {
  public message: string = 'Angaben zum qualifizierten Installateur (Ausweisempfänger)';
  public salutations: SelectOptionInterface[] = Salutations;
  public switch_button_state: boolean = true;
  public expertForm: FormGroup;
  public submitted: boolean = false;
  public myRouter: Router;
  public aData = null;
  public birthday_type = 'text';
  public exercisePeriod = 'text';
  public text;
  public style: StyleModel;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private _dataService: DataService,
    private _textService: TextService,
    private _styleService: StyleService
  ) {
    this.aData = this._dataService.data;
    this.switch_button_state = this.aData['eToggleState'];

    if (this.aData['isGuest']) {
      this.expertForm = this.fb.group({
        salutation: [this.aData['eSalutation'], [Validators.required, Validators.min(1)]],
        title: [this.aData['eTitle']],
        firstName: [this.aData['eFirstName'], [Validators.required, OnlyCharacters]],
        lastName: [this.aData['eLastName'], [Validators.required, OnlyCharacters]],
        phoneNumber: [this.aData['ePhoneNumber'], [Validators.required, OnlyNumbers]],
        mobileNumber: [this.aData['eMobileNumber']],
        mail: [this.aData['eMail'], [Validators.required, Validators.email]]
      });
    } else {
      this.expertForm = this.fb.group({
        salutation: [this.aData['eSalutation'], [Validators.required, Validators.min(1)]],
        title: [this.aData['eTitle']],
        firstName: [this.aData['eFirstName'], [Validators.required, OnlyCharacters]],
        lastName: [this.aData['eLastName'], [Validators.required, OnlyCharacters]],
        phoneNumber: [this.aData['ePhoneNumber'], [Validators.required, OnlyNumbers]],
        mobileNumber: [this.aData['eMobileNumber']],
        mail: [this.aData['eMail'], [Validators.required, Validators.email]],
        birthday: [this.aData['eBirthday'], [Validators.required]],
        areExerciseFieldsShown: [false],
        restiction: [this.aData['eRestriction']],
        exercisePeriod: [this.aData['eExercisePeriod']]
      });
    }

    this.myRouter = this.router;

    this._textService.text.subscribe(text => {
      this.text = text;
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });
  }

  public ngOnInit(): void {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (this.aData['eBirthday']) {
      this.birthday_type = 'date';
    } else {
      this.birthday_type = 'text';
    }

    if (this.aData['eExercisePeriod']) {
      this.exercisePeriod = 'date';
    } else {
      this.exercisePeriod = 'text';
    }
  }

  public onExerciseFieldsVisibilityChange(): void {
    this.expertForm.get('restiction').reset();
    this.expertForm.get('exercisePeriod').reset();
  }

  public toggleState() {
    this.switch_button_state = !this.switch_button_state;
    this._dataService.updateData('eToggleState', this.switch_button_state);
  }

  public changeMe(control: string, event) {
    this._dataService.updateData(control, event.target.value);
  }

  public changeDate(control: string, event) {
    const date = event.target.value.format('YYYY-MM-DD');
    this._dataService.updateData(control, date);
  }

  public onBack(): void {
    this.router.navigate(['/owner']);
  }

  public onSubmit() {
    this.submitted = true;

    if (this.expertForm.valid || (this.switch_button_state && this.aData['isGuest'])) {
      if (this.aData['isGuest']) {
        this.router.navigate(['/installateursausweis']);
      } else {
        this.router.navigate(['/employment']);
      }
    } else if (this.switch_button_state && this.expertForm.get('birthday').valid) {
      if (this.aData['isGuest']) {
        this.router.navigate(['/installateursausweis']);
      } else {
        this.router.navigate(['/employment']);
      }
    }
  }
}
