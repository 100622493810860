import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImprintAndDataProtectionLinksComponent} from "./imprint-and-data-protection-links.component";

@NgModule({
  declarations: [
    ImprintAndDataProtectionLinksComponent
  ],
  exports: [
    ImprintAndDataProtectionLinksComponent
  ],
  imports: [
    CommonModule
  ]
})

export class ImprintAndDataProtectionLinksModule {}
