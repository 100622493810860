import {EnvironmentInterface} from '../app/interfaces/environment.interface';

const hostName: string = window.location.hostname;
const tenantName: string = hostName.split('.')[0];
const subdomain: string = hostName.includes('localhost') ? 'eclou.test-iv.de' : `${tenantName}.test-iv.de`;
const apiUrl: string = hostName.includes('localhost') ? 'https://eclou.test-iv.de/api' : `https://${tenantName}.test-iv.de/api`;

export const environment: EnvironmentInterface = {
  production: false,
  apiUrl: apiUrl,
  subdomain: subdomain,
  adminApiUrl: 'https://admin.test-iv.de/',
  adminUrl: `https://${tenantName}.admin.test-iv.de`
};
