import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {TextService} from '../../services/text.service';
import {StyleService} from '../../services/style.service';
import {IdentityService} from '../../services/identity.service';
import {StyleModel} from "../../models/style.model";

@Component({
  selector: 'app-application-or-guest',
  templateUrl: './application-or-guest.component.html',
  styleUrls: ['./application-or-guest.component.scss']
})

export class ApplicationOrGuestComponent implements OnInit {
  public isGuest: boolean = false;
  public myRouter: Router;
  public tenant: any = null;
  public text = '';
  public style: StyleModel;
  public power_button_state: boolean = false;
  public gas_button_state: boolean = false;
  public water_button_state: boolean = false;
  public gasWater_button_state: boolean = false;
  public commodities: any = null;
  public commoditySelectable: boolean = false;
  public commoditySelected: boolean = false;

  onClickInstallatuer() {
    this.isGuest = false;
    this.data.updateData('isGuest', false);
    this.router.navigate(['/company']);
  }

  onClickGuest() {
    this.isGuest = true;
    this.data.updateData('isGuest', true);
    this.router.navigate(['/company']);
  }

  DataProtectionConfirmed() {
    this.data.updateData('dataProtectionConfirmed', true);
  }

  DataProtectionDeclined() {
    window.location.href = this.text['P0_M_Abort_Link'];
  }

  constructor(
    private router: Router,
    private data: DataService,
    private _textService: TextService,
    private _styleService: StyleService,
    private _identityService: IdentityService
  ) {
    this.myRouter = this.router;

    this._textService.text.subscribe(text => {
      if (text) {
        this.text = text;
        let splitted_string = this.text['P0_M_Info'].split('[replace]');
        this.text['P0_M_Info_H1'] = splitted_string[0];
        this.text['P0_M_Info_H2'] = splitted_string[1];
      }
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });

    this._identityService.tenant.subscribe(tenant => {
      this.tenant = tenant;
      if (tenant && !this.data.data['dataProtectionConfirmed']) {
        document.getElementById('modalbtn').click();
      }
    });

    this._identityService.commodity.subscribe(data => {
      this.commodities = data;
      if (this.commodities != null && this.commodities != undefined) {
        let trueElements = [];
        this.commodities.forEach(data => {
          if (data == true) {
            trueElements.push(data);
          }
        });

        if (trueElements.length > 1) {
          this.commoditySelectable = true;
          this.commoditySelected = false;
        } else {
          this.commoditySelected = true;
          this.data.updateData('commodities', [
            this.commodities[0],
            this.commodities[1],
            this.commodities[2],
            this.commodities[3]
          ]);
        }
      }
    });
  }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  changeCommodity(commodity) {
    switch (commodity) {
      case 'power':
        this.power_button_state = !this.power_button_state;
        break;
      case 'gas':
        this.gas_button_state = !this.gas_button_state;
        break;
      case 'water':
        this.water_button_state = !this.water_button_state;
        break;
      case 'gasWater':
        this.gasWater_button_state = !this.gasWater_button_state;
        break;
    }

    this.commoditySelected = !(!this.power_button_state && !this.gas_button_state && !this.water_button_state && !this.gasWater_button_state);

    const powerSelected = this.power_button_state;
    let gasSelected = this.gas_button_state;
    let waterSelected = this.water_button_state;
    let gasAndWaterSelected = this.gasWater_button_state;

    if (this.commodities[1] && this.commodities[2] && this.commodities[3] && this.gas_button_state && this.water_button_state) {
      gasAndWaterSelected = true;
      gasSelected = false;
      waterSelected = false;
    }

    this.data.updateData('commodities', [powerSelected, gasSelected, waterSelected, gasAndWaterSelected]);
  }
}
