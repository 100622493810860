<div class="d-flex justify-content-center mt-4">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">{{text['P8_Headline']}}</p>
      <p class="font-3">{{text['P8_Info']}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 wizard-col">
          <div class="form-group form-group-IV">
            <div [class.form-item-invalid]="!valid && submitted" class="upload-btn-wrapper form-group form-group-IV" *ngIf="style" [ngStyle]="{'color': style['p']}">
              <button class="form-control form-item shadow-IV giant-button" *ngIf="style" [ngStyle]="{'color': style['p']}">
                <i class="fas fa-file-upload giant-button-icon" *ngIf="style" [ngStyle]="{'color': style['p']}"></i>
              </button>

              <input type="file" name="myfile" title="" (change)="onFileSelected($event)" multiple/>
            </div>
          </div>

          <div class="form-group form-group-IV">
            <div *ngIf="fileList.length > 0">
              <div class="mt-1 fileContainer">
                <div *ngFor="let file of fileList; let i = index" class="fileList text-center form-control form-item customHeight shadow-IV mt-2">
                  <div style="float:right;"><i class="fas fa-trash-alt deleteIcon" (click)="deleteFile(i)"></i></div>
                  <div><span>{{file}}</span></div>
                </div>
              </div>
            </div>

            <div *ngIf="fileList.length == 0">
              <div class="mt-1 fileContainer">
                <div class="fileList text-center form-control form-item customHeight shadow-IV mt-2">
                  <div><span>{{text['P8_Error']}}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button *ngIf="style" [ngStyle]="{'background-color': style['t']}" (click)="onBack()" class="btn btn-prev-step btn-full-size btn-big text-bold font-5 shadow-IV">{{ text['P8_Button_Abort'] }}</button>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button *ngIf="style" [ngStyle]="{'background-color': style['p']}" class="btn btn-next-step btn-full-size btn-big text-bold font-5 shadow-IV" (click)="onSubmit()">{{ text['P8_Button_Next'] }}</button>
        </div>
      </div>
    </div>

    <div class="progress">
      <div class="progress-bar" role="progressbar" style="width: 56%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="style" [ngStyle]="{'background-color': style['p']}"></div>
    </div>
  </div>
</div>
