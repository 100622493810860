import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {TextService} from '../../services/text.service';
import {StyleService} from '../../services/style.service';
import {IdentityService} from '../../services/identity.service';
import {StyleModel} from "../../models/style.model";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-employment-contract',
  templateUrl: './employment-contract.component.html',
  styleUrls: ['./employment-contract.component.scss']
})

export class EmploymentContractComponent implements OnInit {
  fileList: string[] = ['[Noch keine Datei ausgewählt]'];
  message: string = 'Bitte Datei auswählen!';
  submitted: boolean = false;
  valid: boolean = false;
  myRouter: Router;
  aData = null;
  text;
  public style: StyleModel;
  link;
  subdomain;

  constructor(
    private router: Router,
    private data: DataService,
    TextService: TextService,
    private _styleService: StyleService,
    IdentityService: IdentityService
  ) {
    this.aData = data.data;
    this.fileList = this.aData['ecFileList'];
    this.myRouter = this.router;

    IdentityService.subdomain.subscribe(data => {
      this.subdomain = data;
    });

    TextService.text.subscribe(text => {
      if (text) {
        this.text = text;
        this.text['P5_Info'] = this.text['P5_Info'].replace(/href='.*'/gi, this.link);
      }
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
      this.link = "href='" + environment.apiUrl + '/Clients/GetTenantFile/' + this.style['leaflet'] + "'";
      this.text['P5_Info'] = this.text['P5_Info'].replace(/href='.*'/gi, this.link);
    });
  }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.text['P5_Info'] = this.text['P5_Info'].replace(/href='.*'/gi, this.link);
  }

  onFileSelected(event) {
    if (this.fileList.length > 0) {
      if (this.fileList[0] == '[Noch keine Datei ausgewählt]') {
        this.fileList = [];
      }
    }

    let i = 0;
    if (event.target.files.length > 0) {
      for (i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].name == 'item' || event.target.files[i].name == 'undefined') {
        } else {
          this.fileList.push(event.target.files[i].name);
        }
      }
    }
    this.data.updateData('ecFileList', this.fileList);

    for (let i = 0; i < event.target.files.length; i++) {
      this.data.ec_files.push(event.target.files[i]);
    }
  }

  onSubmit() {
    this.submitted = true;
    this.router.navigate(['/qualification']);
  }

  onBack() {
    this.router.navigate(['/expert']);
  }

  deleteFile(index) {
    this.fileList.splice(index, 1);

    var filesToUpload = Array.from(this.data.ec_files);
    filesToUpload.splice(index, 1);

    this.data.ec_files = [];

    for (let i = 0; i < filesToUpload.length; i++) {
      this.data.ec_files.push(filesToUpload[i]);
    }
  }
}
