import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {TextService} from '../../services/text.service';
import {StyleService} from '../../services/style.service';
import {StyleModel} from "../../models/style.model";

@Component({
  selector: 'app-finalscreen',
  templateUrl: './finalscreen.component.html',
  styleUrls: ['./finalscreen.component.scss']
})

export class FinalscreenComponent implements OnInit {
  aData = null;
  text;
  style;

  constructor(private data: DataService, TextService: TextService, private _styleService: StyleService) {
    this.aData = data.data;

    TextService.text.subscribe(text => {
      this.text = text;
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });
  }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  finishButtonClick() {
    window.location.href = this.text['P15_Final_Link'];
  }
}
